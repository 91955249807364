/*
  Under Construction
*/

import { Box } from '@mui/material';
import React from 'react';
import { UnderConstructionText } from '../../shared/constants';

const SeedingRateCalculator = () => (
  <Box>{UnderConstructionText}</Box>
);

export default SeedingRateCalculator;
