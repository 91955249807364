/*
  Under construction
*/

import { Box } from '@mui/material';
import React from 'react';
import { UnderConstructionText } from '../../shared/constants';

const MixMaker = () => (
  <Box>{UnderConstructionText}</Box>
);

export default MixMaker;
